<div class="section">
  <ag-grid-angular
    [style.height]="domLayout === '' ? height + 'px' : ''"
    (exportName)="exportName"
    [excelStyles]="excelStyles"
    [getContextMenuItems]="getContextMenuItems1"
    #agGrid style="width: 100% "
    id="{{idGrid}}"
    class="ag-theme-balham {{listsData.length > 0 ? '' : 'hidden_row'}}"
    [pinnedBottomRowData]="isShowTotalBottom ? pinnedBottomData : []"
    [debounceVerticalScrollbar]="true"
    [rowData]="listsData"
    [domLayout]="domLayout"
    [frameworkComponents]="frameworkComponents"
    [floatingFiltersHeight]="floatingFiltersHeight"
    [autoGroupColumnDef]="autoGroupColumnDef"
    [columnDefs]="columnDefs"
    [detailCellRendererParams]="detailCellRendererParams"
    [headerHeight]="headerHeight"
    [groupDefaultExpanded]="groupDefaultExpanded"
    [groupDisplayType]="'groupRows'"
    [singleClickEdit]="true"
    [isRowSelectable]="isRowSelectable"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [tooltipShowDelay]="tooltipShowDelay"
    (gridReady)="onGridReady($event)"
    [defaultColDef]="defaultColDef"
    [enableCellTextSelection]="true"
    [suppressCopyRowsToClipboard]="true"
    [enableRangeSelection]="true"
    [rowSelection]="rowSelection"
    (cellClicked)="CellClicked($event)"
    (rowDoubleClicked)="RowDoubleClicked($event)"
    (cellDoubleClicked)="CellDoubleClicked($event)"
    [animateRows]= "true"
    [isRowMaster]="isRowMaster"
    [masterDetail]="true"
    [skipHeaderOnAutoSize]= "true"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    [overlayLoadingTemplate]="overlayLoadingTemplate"
    (rowSelected)="onRowSelected($event)"
    (cellValueChanged)="onCellValueChanged($event)"
    [getRowHeight]="getRowHeight"
    [suppressPropertyNamesCheck]="true"
    [modules]="modules"
    [rowClassRules]="rowClassRules"
    (columnResized)="onColumnResized($event)"
    (bodyScroll)="handleScroll($event)"
    (rowGroupOpened)="rowGroupOpened($event)"
    [getMainMenuItems]="getMainMenuItems"
    [gridOptions]="gridOptions"
    (firstDataRendered)="onFirstDataRendered($event)"
  >
  </ag-grid-angular>
</div>

<!--[suppressPropertyNamesCheck]="true" // bỏ thông báo-->
<!-- [floatingFilter]="floatingFilter" -->

<!-- [getRowId]="getRowId" -->

<!-- (onGridSizeChanged)="onGridSizeChanged" -->

<!-- [groupIncludeFooter]="groupIncludeFooter" -->
<!-- [groupIncludeTotalFooter]="groupIncludeTotalFooter" -->
