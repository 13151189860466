<section>
    <div class="grid">
        <div class="col-9">
            <div class="grid">
                <div class="col-12">
                    <div class="grid-default">
                        <h3 class="title">{{'_summary_' | translate}}</h3>
                        <div class="wrap-grid grid-full-height">
                            <app-list-grid-angular  *ngIf="gridView.length > 0"
                            [listsData]="dataView" [height]="heightGrid" [domLayout]="'autoHeight'" [idGrid]="'idGrid0'"
                            [columnDefs]="gridView"></app-list-grid-angular>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="grid-default">
                        <h3 class="title">{{'_user_summary_' | translate}}</h3> 
                        <div class="wrap-grid grid-full-height">
                            <app-list-grid-angular  *ngIf="gridAccess.length > 0"
                            [listsData]="dataAcess" [height]="heightGrid" [idGrid]="'idGrid1'"
                            [columnDefs]="gridAccess"></app-list-grid-angular>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="grid-default">
                        <h3 class="title">{{'_group_of_new_users_' | translate}}</h3> 
                        <div class="wrap-grid grid-full-height">
                            <app-list-grid-angular  *ngIf="gridCreated.length > 0"
                            [listsData]="dataCreated" [height]="heightGrid" [idGrid]="'idGrid2'"
                            [columnDefs]="gridCreated"></app-list-grid-angular>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="col-3">
            <div class="section activites">
                <h3 class="title">{{'_activitys_' | translate}}</h3> 
                <div class="content">
                <p-timeline [value]="timelines" align="left" class="timeline-default">
                    <ng-template pTemplate="content" let-event>
                        <p-card [header]="event.status" [subheader]="event.date">
                            <div class="timeline-item">
                                <span class="main-color" style="color: #292663;">{{event.action}}</span>
                                <i>{{event.time}}</i>
                                <span class="main-color" style="
                                font-size: 13px;
                                margin-top: 2px;
                                display: block;
                            "><b>{{event.createdBy}}</b></span> 
                            </div>
                        </p-card>
                    </ng-template>
                </p-timeline>
                </div>
            </div>
        </div>
    </div>
</section>