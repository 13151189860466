import {HttpClient, HttpBackend} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {delay, retry, tap} from 'rxjs/operators';
import {Config} from './config.models';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  config: Config | undefined;

  constructor(private handler: HttpBackend, private httpClient: HttpClient, private router: Router) {
  }

  loadConfig(): Promise<Config> {
    const redirectUri = `${window.location.origin}/auth-callback`;
    const logoutUrl = `${window.location.origin}/silent-refresh.html`;
    return new HttpClient(this.handler)
      .get<Config>('./assets/appsetings/appsetings.json')
      .pipe(retry(3))
      .toPromise()
      .then((config) => (this.config = {...config, redirectUri: redirectUri, logoutUrl: logoutUrl}));
  }

  get issuer(): string | undefined {
    return this.config?.issuer;
  }

  get clientId(): string | undefined {
    return this.config?.clientId;
  }

  get redirectUri(): string | undefined {
    return this.config?.redirectUri;
  }

  get logoutUrl(): string | undefined {
    return this.config?.logoutUrl;
  }

  get responseType(): string | undefined {
    return this.config?.responseType;
  }

  get scope(): string | undefined {
    return this.config?.scope;
  }

  get requireHttps(): boolean | undefined {
    return this.config?.requireHttps;
  }

  get showDebugInformation(): boolean | undefined {
    return this.config?.showDebugInformation;
  }

  get disableAtHashCheck(): boolean | undefined {
    return this.config?.disableAtHashCheck;
  }

  get apiBase(): string | undefined {
    return this.config?.apiBase;
  }

  get apiBizzone(): string | undefined {
    return this.config?.apiBizzone;
  }

  get pathUpload(): string | undefined {
    return this.config?.pathUpload;
  }

}

export const appConfigProvide = (appConfigService: AppConfigService) => {
  return () => appConfigService.loadConfig();
};
