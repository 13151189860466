// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const host = {
  authServer: 'https://idp-dev.unicloudgroup.com.vn/realms/umee-finance-dev',
  apiCoreServer: 'https://dev-api-umeeconfig.unicloudgroup.com.vn',
  apiUploadS3: 'https://dev-umee-finance-strapi.ssf.vn',
  apiKSFServerInvest: 'https://dev-api-umeeconfig.unicloudgroup.com.vn',  
};

const authenSettings = {
  authority: host.authServer,
  client_id: 'umee-finance-frontend-config-client',
  redirect_uri: 'https://dev-web-config.umee.com.vn/auth-callback',
  post_logout_redirect_uri: 'https://dev-web-config.umee.com.vn',
  response_type: 'code',
  scope: 'openid profile',
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: true,
  silent_redirect_uri: 'https://dev-web-config.umee.com.vn/silent-refresh.html'
};

export const environment = {
  production: true,
  apiCoreBase: host.apiCoreServer,
  apiCoreBaseInvest: host.apiKSFServerInvest,
  apiUploadS3Base: host.apiUploadS3,
  authBase: host.authServer + '/connect/token',
  authenSettings: authenSettings,
};
