// Author: T4professor

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
// import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid';

@Component({
  selector: 'app-button-renderer',
  template: `
          <div style="display: flex;">
            <ng-container *ngFor="let item of params.buttons; let i = index">
                <button pButton  (click)="onClick($event, i)"  [hidden]="item?.hide"  class="p-button-sm {{item.class}}">
                   <span  [class]="item.icon"></span> {{item.label}}
                </button>
            </ng-container>
          </div>
    `
})

export class ButtonRendererComponent implements ICellRendererAngularComp {

  params;
  label: string;

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event, idx) {
    console.log(this.params);
    if (this.params.buttons[idx].onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        index: this.params.rowIndex
        // ...something
      };
      this.params.buttons[idx].onClick(params);
    }
  }
}
