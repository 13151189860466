import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MenuItem} from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import {AuthService} from 'src/app/services/auth.service';
import {ThemeService} from 'src/app/theme.service';
import queryString from 'query-string';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  userName = '';
  avatarUrl = '';
  items: MenuItem[] = [];
  private readonly unsubscribe$: Subject<void> = new Subject();
  cats = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private themeService: ThemeService,
    public translate: TranslateService,
    private apiService: ApiService,
  ) {
    this.items = [{
      label: 'Logout',
      icon: 'pi pi-refresh',
      command: () => {
        this.logout();
      }
      // items: [{
      //     label: 'Logout',

      // },
      // {
      //     label: 'Delete',
      //     icon: 'pi pi-times',
      //     command: () => {
      //         this.delete();
      //     }
      // }
    }];
    translate.addLangs(['vi', 'en']);
    if (localStorage.hasOwnProperty('currentLang') && localStorage.getItem('currentLang') != null) {
      const getLang = localStorage.getItem('currentLang');
      translate.use(`${getLang}`);
    } else {
      translate.setDefaultLang('vi');
      translate.use('vi');
    }
  }

  logout() {
    this.authService.signout();
  }

  ngOnInit() {
    this.userName = this.authService.getUserName();
    this.getWebManagements();
  }

  update() {

  }

  saveLang(lang: string) {
    localStorage.setItem('currentLang', lang);
    window.location.reload();
  }

  delete() {

  }

  changeTheme(theme: string) {
    this.themeService.switchTheme(theme);
  }

  goToHome() {
    this.router.navigate(['/home']);
  }

  getWebManagements() {
    const queryParams = queryString.stringify({webId: 'F64B0750-2B1D-4011-89FF-36642C1D65BE'});
    this.apiService.getWebManagements(queryParams).pipe(takeUntil(this.unsubscribe$))
    .subscribe( (results: any) => {
      if (results && results.length > 0)  {
        this.cats = results;
      }
    });
  }

}
